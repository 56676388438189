<template>
    <div class="content d-flex align-items-center justify-content-center">
        <div class="aside-right ">
            <!----Signup------>
            <div class="card" v-if="card.signUpCard" @click="error = ''">
                <div class="card-body">
                    <h4 class="card-title text-center">SignUp</h4>
                    <div class="fields">
                        <label>Username <span class="text-danger">*</span></label>
                        <InputText type="text" v-model="username" placeholder="Username"/>
                        <label class="mt-4" type="text">Email <span class="text-danger">*</span></label>
                        <InputText type="text" v-model="email" placeholder="Email"/>
                        <label class="mt-4" for="Password">Password <span class="text-danger">*</span></label>
                        <InputText type="password" toggleMask v-model="password" placeholder="Password"/>
                        <span v-if="error != ''">{{error.message}}</span>
                    </div>
                    <div class="login-btn mt-4">
                        <router-link to="/login"><h2 class="navigation-link">Have an account?<b>Sign in</b></h2></router-link> 
                        <Button label="Create Account" class="p-button-primary" @click="signUp()"/>
                    </div>
                </div>
            </div>
            <!-- --Confirmation COde----
            <ConfirmSignup v-if="card.confirmCodeCard" :username="username"/>             -->
        </div>
        <div class="loader" id="propertiesLoader" v-if = "loader">
            <ProgressSpinner style="width:50px;height:50px" />
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    name:"SignUp",
    data(){
        return {
            username    : "",
            password    : "",
            email       : "",
            error       : "",
            card: {
                signUpCard      : true,
                confirmCodeCard : false,
            },
            loader      : false,
        }
    },
    methods :{
         ...mapActions({
            signupVue                   : "auth/signUp",
        }),
        async signUp(){
            this.loader = true;
            try {
                await this.signupVue({
                    username: this.username,
                    password: this.password,
                    email   : this.email
                });
                this.loader = false 
                this.$router.push('/confirmsignup/'+this.username)
            } catch (error) {
                this.loader = false 
                this.error = error;
            }
        },
    },

   
    
}
</script>

<style lang="scss" scoped>
@import "Auth.scss";
</style>